<!-- 棋牌游戏 -->
<template>
    <div class="page-chess">
        <div class="page-title-box">
            <img loading="lazy" v-lazy="require('../../assets/images/game/black_board_a001_title.png')"> 
        </div>
        <div class="page-chess-box">
            
            <!-- 二级导航 -->
            <div class="chess-tab">
                <swiper :options="swiperOption">
                    <swiper-slide class="item" v-for="(item,index) in newList" :key="index">
                        <div class="gameList">
                            <div class="item" :class="{'item1':curId === citem.id}"
                            v-for="(citem,cindex) in item" :key="cindex" @click="tapGame(citem)">
                                {{citem.name}}
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <!--以下看需要添加-->
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div> 
            </div>
            
            <!-- 操作栏 -->
            <div class="chess-controls">
                <!-- Tab -->
                <div class="chess-control-tab">
                    <div :class="{'active-control-tab': activeTab == 1}" @click="pageChange">{{$t("gamePage.text0")}}</div>
                    <!-- <div v-show="activeGame" :class="{'active-control-tab': activeTab == 2}" @click="getRecommend">热门游戏</div> -->
                </div>

                <!-- 搜索框 -->
                <div class="chess-control-serach" v-show="activeTab != 2">
                    <Input  v-model.trim="searchText" :placeholder='$t("gamePage.text4")'></Input>
                    <img  @click="search" src="../../assets/images/game/black_casion_a001_search.png" alt="">
                </div>
            </div>

            <!-- 游戏列表 -->
            <div class="chess-lists">
                <div class="chess-list-box">
                    <div class="chess-list-item" v-for="(item, i) in showList" :key="i" @click="_getToken(item,1)">
                        <div v-show="item.nameEn" class="item-logo">{{ item.nameEn }}</div>
                        <div class="item-img">
                            <img loading="lazy" v-lazy="item._pictureUrl">
                        </div>
                        <div class="item-name">{{ item.name || '--' }}</div>
                    </div>
                </div>
            </div>
            <!-- 分页-只有全部游戏的时候才展示分页 -->
            <div class="pagination-box">
                <Pagination
                v-show="activeGame && activeTab == 1"
                layout="prev, pager, next"
                :hide-on-single-page="true"
                :current-page.sync="pages.currPage"
                :page-size="pages.pageSize"
                :total="listTotal"
                @size-change="pageChange"
                @current-change="pageChange"
                ></Pagination>
            </div>
        </div> 
    </div>
</template>

<script>
import { _getToken } from '../../core/comp/common'
import { mapGetters } from 'vuex'
import { Input, Pagination,Message } from 'element-ui'
import { _thirdList, _searchGameAction, _recommendGameByIDAction } from '../../core/comp/pages/games'

export default {
    components: {
        Input,
        Pagination,
    },
    data () {
        return {
            curId: '',
            loading: false,
            swiperOption: {//swiper3
                // autoplay: 3000,
                speed: 1000,
                slidesPerView: 1,
                spaceBetween: 30,
                // loop:true,
                prevButton:'.swiper-button-prev',
                nextButton:'.swiper-button-next',
                pagination: '.swiper-pagination',
                paginationType:'fraction',
            },
            searchText: '', // 当前搜索的内容
            searchList: [], // 搜索结果

            activeGame: '', // 当前激活的游戏平台ID
            activeTab: 1, // 当前激活的分类 1-全部  2-热门游戏
            newList:[],// 分割之后的新数组
            pages: { // 分页管理
                currPage: 1,
                pageSize: 24,
            }
        }
    },
    watch: {
        activeGame (val) { // 监听平台变化
            if (this.gameChessPages[val]) { // 当平台变化时发现该平台有缓存的数据 则直接展示
                this.pages = JSON.parse(JSON.stringify(this.gameChessPages[val]))
            }
        },
        // lists (val) { // 监听二级分类数据变化，当变化时默认获取第一个元素的子游戏列表
        //     if (val && val[0]) {
        //         this.tapGame(val[0]) // 当前页刷新时的初始化
        //     }
        // }
        $route: {  
            handler: function (val) {
                if(val) {
                    let id = val.query.id
                    if(id != 5) {
                        let obj = this.lists.find( item => {
                            return item.id == id
                        })
                        this.tapGame(obj) // 初始化
                    } else {
                        this.tapGame(this.lists[0]) // 初始化
                    }
                }  
                
            },
            immediate: true
        }
    },
    mounted () {
        this.splitList()
    },
    computed: {
        showList () { // 展示的游戏列表
            if (this.activeGame) {
                // 全部游戏列表
                if (this.activeTab == 1) return this.gameChessLists[this.activeGame] || []
                // 热门游戏列表
                if (this.activeTab == 2) return this.gameChessRecommen[this.activeGame] || []
            }
            return this.searchList // 搜索结果
        },
        listTotal () { // 列表总数
            if (this.gameChessPages[this.activeGame]) return this.gameChessPages[this.activeGame].total
            return 0
        },
        lists () { // 二级导航
            return this.gameMenu.arr_qp || []
        },
        ...mapGetters([
            'isLogin',
            'gameMenu', // 游戏分类
            'gameChessLists', // 三级游戏 全部列
            'gameChessPages', // 三级游戏 全部列表的页面状态
            'gameChessRecommen', // 三级游戏 热门列
        ])
    },
    methods: {
        _getToken,
        // 数组切割 列表 每组几个
        splitList() {
            for (let i = 0; i < this.lists.length; i += 8) {
                this.newList.push(this.lists.slice(i, i + 8));
            }
        },
        // 搜索
        search () {
            if(!this.isLogin) return  Message.warning('请先登录')
            if (this.searchText === '') return
            this.curId = ""
            this.activeGame = ''
            this.activeTab = 1
            console.log('---搜索', this.searchText)
            setTimeout(() => {
                this.searchList = []
                _searchGameAction(this.searchText).then(res => {
                    if (res) this.searchList = res
                })
            }, 0)
        },
        // 翻页
        pageChange () {
            this.activeTab = 1 // 锁定为全部游戏
            this.tapGame(this.lists.find(item => item.id == this.activeGame))
        },
        // 点击游戏平台
        tapGame (item) {
            if (this.loading) return
            this.curId = item.id
            this.activeTab = 1
            console.log('---点击了', item)
            this.activeGame = item.id
            setTimeout(() => {
                // 如果发现当前缓存的是正要请求的该页数据，则不用发送请求，直接用缓存即可，可以减少大量请求
                if (this.gameChessPages[this.activeGame] && this.gameChessPages[this.activeGame].currPage == this.pages.currPage) return
                this.loading = true
                _thirdList({ item, pages: this.pages }, 'chess').then(() => {
                    setTimeout(() => {
                        this.loading = false
                    }, 100)
                })
            }, 0)
        },
        // 获取推荐游戏
        getRecommend () {
            this.activeTab = 2 // 锁定为推荐游戏
            const item = this.lists.find(item => item.id == this.activeGame)
            setTimeout(() => {
                console.log('---推荐', item)
                _recommendGameByIDAction(item, 3)
            }, 0)
        }
    }
}
</script>
<style lang="less" scoped>
@import url("../../assets/css/page-chess.less");
</style>